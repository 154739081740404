var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-card",
        {
          staticClass: "text--black card-border",
          staticStyle: { overflow: "auto" }
        },
        [
          _c(
            "b-row",
            { staticClass: "desktop-fit", attrs: { "align-h": "center" } },
            _vm._l(_vm.statusHeaders, function(stat) {
              return _c(
                "b-col",
                {
                  key: stat.id,
                  staticClass: "queue header d-none d-lg-flex",
                  attrs: { cols: "12", lg: "" }
                },
                [
                  _c("div", { staticClass: "h2 header" }, [
                    _vm._v(_vm._s(stat.name) + "(" + _vm._s(stat.length) + ")")
                  ]),
                  _c("br")
                ]
              )
            }),
            1
          ),
          _c(
            "b-row",
            { staticClass: "desktop-fit" },
            [
              !_vm.noItems && !_vm.loading
                ? _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-row",
                        { attrs: { "align-h": "center" } },
                        _vm._l(_vm.statuses, function(stat, key) {
                          return _c(
                            "b-col",
                            {
                              key: stat.id,
                              staticClass: "queue items",
                              attrs: { cols: "12", lg: "" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "h2 header d-block d-lg-none" },
                                [_vm._v(_vm._s(stat.name))]
                              ),
                              _vm._l(stat.items, function(item) {
                                return _c("Onboarding", {
                                  key: item.id,
                                  attrs: {
                                    item: item,
                                    hideBack: key == 0,
                                    hideForward: key == _vm.statuses.length - 1,
                                    statusOptions: _vm.statusOptions
                                  },
                                  on: {
                                    refresh: function($event) {
                                      return _vm.getStatusData()
                                    }
                                  }
                                })
                              })
                            ],
                            2
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("b-col", { attrs: { cols: "12" } }, [
                !_vm.loading && !_vm.firstload && _vm.noItems
                  ? _c(
                      "div",
                      { staticClass: "text-center h2 text-black-50 my-4" },
                      [_vm._v(" No companies being onboarded ")]
                    )
                  : _vm._e(),
                _vm.loading
                  ? _c(
                      "div",
                      { staticClass: "text-center h2 my-4" },
                      [_c("b-spinner")],
                      1
                    )
                  : _vm._e()
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }